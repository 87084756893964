import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Container,
  Grid,
  Typography,
  Box,
  Divider,
  useMediaQuery,
  Button,
  Grow,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import Layout from "../components/Layout"
import DestinationCard from "../components/Destinations/DestinationCard"
import Cards from "../components/Cards"
import Textfield from "../components/TextField"
import Footer from "../components/Footer"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import { SEO } from "../components/SEO"

const Destinations = ({ location, pageContext, data }) => {
  const [displayCount, setDisplayCount] = useState(9)
  const mdUp = useMediaQuery(zhutravelTheme.breakpoints.up("md"))
  const cities = data.allContentfulCityModel.edges

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO/>
      <Container>
        <Box mt={mdUp ? 11 : 7} mb={mdUp ? 13 : 6}>
          <Typography variant="h1">Destinations</Typography>
        </Box>
        <Grid container spacing={3}>
          {cities.slice(0, displayCount).map(({ node }, index) => (
            <Grow
              key={node.cityName}
              in={index < displayCount}
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12} md={4}>
                <DestinationCard destination={node} btnLabel="Learn more" />
              </Grid>
            </Grow>
          ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          mt={mdUp ? 5 : 3}
          mb={mdUp ? 15 : 10}
        >
          <Button
            startIcon={<AddIcon />}
            onClick={() => setDisplayCount(displayCount + 3)}
            disabled={displayCount >= cities.length}
          >
            Load more
          </Button>
        </Box>
      </Container>
      <Divider />
      <Cards
        tourPageMainData={data.allContentfulTourPageModel?.edges?.[0]?.node}
        dmcPageMainData={data.allContentfulDmcPageModel?.edges?.[0]?.node}
        investPageMainData={data.allContentfulInvestPageModel?.edges?.[0]?.node}
        title="Our services"
        isBlog
      />
      <Divider />
      <Textfield
        backgroundColor={"black"}
        customImage={data.file.childImageSharp}
        locale={pageContext.locale}
      />
      <Divider />
      <Footer locale={pageContext.locale} />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String!) {
    allContentfulCityModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          node_locale
          slug
          cityName
          countryFlag {
            fluid(maxWidth: 50) {
              ...GatsbyContentfulFluid
            }
          }
          cardTruncateDescription {
            cardTruncateDescription
          }
          bannerImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulDmcPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          topSectionPageTitle
          topSectionDescription {
            topSectionDescription
          }
        }
      }
    }
    allContentfulTourPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageSubTitle
        }
      }
    }
    allContentfulInvestPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          pageDescription {
            pageDescription
          }
        }
      }
    }
    file(name: { eq: "FundoGiris" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Destinations
